.AppBar-text {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    letter-spacing: 0.44px;
    line-height: 15px;
    font-size: 14px;
    text-transform: uppercase;
    vertical-align: middle;
    margin-left: -10px;
    text-align: left;
}

.AppBar-tabs {
    justify-content: flex-end;
}

.AppBar {
    border-bottom: 4px solid rgb(155, 2, 2);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px;
}
