.card {
    margin-top: 20px;
    margin-bottom: 20px;
}

.card-title {
    text-align: left;
}

.QuestionCard-button {
    margin-top: 5px;
    border-style: solid;
    border-color: rgb(70, 70, 70);
    color: black;
}

.btn-primary:hover {
    border-color: rgb(70, 70, 70);
}
