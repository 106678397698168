.WelcomeContainer {
    background-color:  rgb(155, 2, 2);
    max-width: 100%;
    padding: 5%;
    color: white;
    text-align: left;
}

.WelcomeContainer {
    background-color:  rgb(155, 2, 2);
    max-width: 100%;
    padding: 5%;
    color: white;
    text-align: left;
}

.WelcomeContainer h1, h2, h3, h4, h5, h6 {
    color: white;
    letter-spacing: .06em;
    text-align: left;
}

.WelcomeContainer span.badge {
    font-weight: bolder;
    margin-right: 5px;
}

.WelcomeContainer h1 {
    font-size: xxx-large;
}

.WelcomeContainer h3 {
    font-size: large;
}

.HomeContainer {
    padding: 0 !important;
}

.HighlightsContainer {
    background-color:  white;
    max-width: 100%;
    padding: 3%;
    color: white;
    text-align: center;
    margin-top: 10px;
}

.HighlightsContainer h1 {
    text-transform: uppercase;
    margin: 2px;
}
