.CircularProgressbar-trail {
  stroke: #eee;
}
.ProgressbarContent{
    color: #222;
}

.ProgressbarContent-title {
    color: #222;
    font-size: 2.2em;
    font-weight: 900;
    margin-bottom: 0px;
}

.ProgressbarContent-subtitle {
    margin-top: -10px;
    color: #555
}
