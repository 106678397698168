.CardIcon {
    margin: 5px 0px;
    font-size: 2.4em;
    stroke-width: .2px;
    stroke: #fff;
}

.TimelineCard {
    text-align: left;
    font-family: 'Roboto', sans-serif;
    border-radius: 1em;
    padding: 10px;
}

.TimelineCardTitle {
    font-size: 1.6em;
    font-weight: bolder;
    margin: 10px 0px 10px 0px;
}

.TimelineCardSubtitle {
    margin: 0px 0px 25px 0px;
    font-size: 1em;
    color: #ccc;
    font-weight: 500;
}

.TimelineCardText {
    font-weight: 500;
    font-size: 1.1em;
}

.TimelineCardBadge {
    font-size: .8em;
    color: #fff;
    background-color: #333 !important;
    padding: 8px 10px;
    margin-right: 5px;
}
