.QuizStartButton {
    background-color: rgb(155, 2, 2) !important;
    border: 0px;
    padding: 10px 50px;
}

.SelectionCard{
    border-bottom: 3px solid rgb(155, 2, 2);
    border-bottom-left-radius: 0%;
    border-bottom-right-radius: 0%;
    width: 95%;
}
