.divider-container {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 20px 0;
}

.divider-line {
    position: relative;
    top: 10px;
    text-align: center;
    margin: 0 0 1.9rem;
    content: "";
    display: block;
    height: .125rem;
    background: #000;
    left: 0;
    right: 0;
    z-index: 1;
}

.divider-line h6 {
    background-color: #fff;
    position: relative;
    top: -11px;
    display: inline-block;
    z-index: 3;
    padding: 0 .6em;
    font-family: Lato,tahoma,helvetica,arial,sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: black;
}
