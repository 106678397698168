.QuizSubmitButtonContainer {
    margin-top: 20px;
}

.QuizSubmitButton {
    margin: 20px;
    background-color: rgb(155, 2, 2) !important;
    border: 0px;
    padding: 10px 40px;
}
