.AppFooter {
    border: 0;
    border-top: 2px !important;
    border-style: solid !important;
    border-color: rgb(226, 226, 226) !important;
    margin-top: 5%;
    padding: 2%;
    text-align: right;
}

.AppFooterIcon {
    margin-left: 15px;
    color: rgb(149, 149, 149);
}
