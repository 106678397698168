body {
  margin: 0;
  font-family: -apple-system, 'Lato', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  color: #333;
  font-weight: 700;
  font-size: 100%;
}

a {
  color: rgb(155, 2, 2);
}

.PageContainer {
  padding: 14px;
  margin-top: 8px;
}

.c-red {
  color: rgb(155, 2, 2);
}
