/* The actual timeline (the vertical ruler) */
.main-timeline {
    position: relative;
    margin-top: 40px !important;
}

/* The actual timeline (the vertical ruler) */
.main-timeline::after {
    content: "";
    position: absolute;
    width: 3px;
    background-color: #333;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

/* Container around content */
.timeline {
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.timeline::after {
    content: "";
    position: absolute;
    width: 35px;
    height: 35px;
    right: -17px;
    background-color: #333;
    top: 5px;
    border-radius: 50%;
    z-index: 1;
}

/* Place the container to the left */
.left {
    padding: 0px 40px 0px 0px;
    left: 0;
}

/* Place the container to the right */
.right {
    padding: 0px 0px 0px 40px;
    left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
    color: #000;
    content: "";
    position: absolute;
    top: 18px;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px !important;
    border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
    color: #000;
    content: "";
    position: absolute;
    top: 18px;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0 !important;
    border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
    left: -19px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

    /* Place the timelime to the left */
    .main-timeline::after {
        left: 31px;
    }

    /* Full-width containers */
    .timeline {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }

    /* Make sure that all arrows are pointing leftwards */
    .timeline::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0 !important;
        border-color: transparent white transparent transparent;
    }

    .timeline-red::before {
        border: medium solid #e5081a;
        border-color: transparent #e5081a transparent transparent;
    }

    .timeline-yellow::before {
        border: medium solid #ff6701;
        border-color: transparent #ff6701 transparent transparent;
    }

    .timeline-purple::before {
        border: medium solid #45218a;
        border-color: transparent #45218a transparent transparent;
    }

    .timeline-blue::before {
        border: medium solid #195ebb;
        border-color: transparent #195ebb transparent transparent;
    }

    .timeline-green::before {
        border: medium solid #277336;
        border-color: transparent #277336 transparent transparent;
    }

    /* Make sure all circles are at the same spot */
    .left::after,
    .right::after {
        left: 12px;
    }

    .left::before {
        right: auto;
    }

    /* Make all right containers behave like the left ones */
    .right {
        left: 0%;
    }
}

.gradient-red {
    /* fallback for old browsers */
    background: #e5081a;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right,
            #e5081a,
            rgb(155, 2, 2, ));

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right,
            #e5081a,
            rgb(155, 2, 2));
}

.right-red::before {
    border: medium solid #e5081a;
    border-color: transparent #e5081a transparent transparent;
}

.left-red::before {
    border: medium solid rgb(155, 2, 2);
    border-color: transparent transparent transparent rgb(155, 2, 2);
}

.gradient-purple {
    /* fallback for old browsers */
    background: #45218a;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right,
            #45218a,
            #8059e0);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right,
            #45218a,
            #8059e0);
}

.right-purple::before {
    border: medium solid #45218a;
    border-color: transparent #45218a transparent transparent;
}

.left-purple::before {
    border: medium solid #8059e0;
    border-color: transparent transparent transparent #8059e0;
}

.gradient-yellow {
    /* fallback for old browsers */
    background: #e5081a;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right,
            #ff6701,
            #ff8801);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right,
            #ff6701,
            #ff8801);
}

.right-yellow::before {
    border: medium solid #ff6701;
    border-color: transparent #ff6701 transparent transparent;
}

.left-yellow::before {
    border: medium solid #ff8801;
    border-color: transparent transparent transparent #ff8801;
}

.gradient-blue {
    /* fallback for old browsers */
    background: #195ebb;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right,
            #195ebb,
            #1b27d2);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right,
            #195ebb,
            #1b27d2);
}

.right-blue::before {
    border: medium solid #195ebb;
    border-color: transparent #195ebb transparent transparent;
}

.left-blue::before {
    border: medium solid #1b27d2;
    border-color: transparent transparent transparent #1b27d2;
}

.gradient-green {
    /* fallback for old browsers */
    background: #277336;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right,
            #277336,
            #32c839);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right,
            #277336,
            #32c839);
}

.right-green::before {
    border: medium solid #277336;
    border-color: transparent #277336 transparent transparent;
}

.left-green::before {
    border: medium solid #32c839;
    border-color: transparent transparent transparent #32c839;
}

.TimelineCard {
    color: white;
    border: 0px;
}

.TimelineYear {
    position: relative !important;
    z-index: -2 !important;

    background-color: #333 !important;
    font-size: x-large;
    padding: 10px 30px;
    letter-spacing: .2em;
}
